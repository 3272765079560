<template>
    <v-dialog
        v-model="dialog"
        max-width="290"
    >
        <v-card>
            <v-card-title class="headline text-center d-block">{{ $t("modal.survey_locked.title") }}</v-card-title>

            <v-card-text>
                <div class="text-center">
                    <v-icon size="70" class="mb-2" color="primary">lock</v-icon>
                    <p>{{ $t("modal.survey_locked.body") }}</p>
                    <v-btn color="primary" min-width="150" outlined rounded @click="onCloseModal">
                        {{ $t('modal.basic.close') }}
                    </v-btn>
                </div>
            </v-card-text>

        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ModalSurveyLocked',
    data: () => ({
        dialog: true
    }),
    methods: {
        onCloseModal() {
            this.$store.dispatch('modals/closeModal');
        }
    }
};
</script>
