<template>
    <v-list>
        <v-list-item link @click="onOpenPendingSurvey(survey)" v-for="(survey, index) in pendingSurveys"
                     :key="survey.id" class="u-border-bottom"
                     :class="{'u-border-bottom-none': (index + 1) === pendingSurveys.length }">
            <v-list-item-avatar>
                <v-avatar color="primary" :size="40">
                    <span class="white--text"
                          style="font-size:11px; font-weight: 700">{{ survey.createdAt | formatDateAlternate }}</span>
                </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>{{ getLocalizedValue(survey.title) }}</v-list-item-title>
                <v-list-item-subtitle>{{ survey.ship ? survey.ship : "N/A Ship" }} /
                    {{ survey.imo ? survey.imo : "N/A IMO" }}
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
                <status :status="survey.status" />
            </v-list-item-action>
        </v-list-item>
    </v-list>
</template>

<script>
import Status from "../../UI/Status";
import GetLocalizedValue from "@/mixins/GetLocalizedValue";

export default {
    name: "ListPendingSurveys",
    mixins: [GetLocalizedValue],
    components: {
        Status
    },
    data: () => ({
        onShowLockedSurveyModal: false
    }),
    props: {
        pendingSurveys: {
            type: Array,
            required: true
        }
    },
    methods: {
        onOpenPendingSurvey(survey) {
            if (survey.status === "PARTIALLY_SUBMITTED") {
                this.$router.push({
                    name: "survey-instance",
                    params: { surveyId: survey.id, surveyVersion: survey.version }
                });
            } else if (survey.status === "OPEN") {
                this.$router.push({
                    name: "survey-view",
                    params: { surveyId: survey.id, surveyVersion: survey.version }
                });
            } else {
                this.$store.dispatch("modals/toggleModal", "onShowLockedSurveyModal");
            }
        }
    }
};
</script>
