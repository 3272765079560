<template>
    <v-list v-if="surveys.length > 0">
        <v-list-item link @click.stop="gotoSurvey(survey)" v-for="(survey, index) in surveys" :key="index"
                     class="u-border-bottom" :class="{'u-border-bottom-none': (index + 1) === surveys.length }">
            <v-list-item-avatar>
                <v-avatar color="primary" :size="40">
                    <span class="white--text" style="font-size:11px; font-weight: 700">{{
                            survey.createdAt | formatDatePendingLocal
                        }}</span>
                </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>{{ getLocalizedValue(survey.title) }}
                    </v-list-item-title>
                <span class="block" v-if="survey.ship">({{ survey.ship }})</span>
                <span class="block" v-if="survey.imo">({{ survey.imo }})</span>
            </v-list-item-content>
            <v-list-item-action>
                <div class="d-flex">
                    <status :status="survey.status"/>
                    <v-icon class="ml-3" color="red" @click.stop="triggerDeleteModal(survey.tempIdFromDB)">delete
                    </v-icon>
                </div>
            </v-list-item-action>
        </v-list-item>
        <modal-delete-survey v-if="showDeleteSurveyModal" @confirmDelete="deleteSurvey"
                             @closeModalDelete="showDeleteSurveyModal = false"/>
    </v-list>
</template>

<script>
import Status from '../../UI/Status';
import { deletePendingSurveyByID } from '../../../helpers/indexedDB';
import ModalDeleteSurvey from '../Modals/ModalDeleteSurvey';
import GetLocalizedValue from '@/mixins/GetLocalizedValue';

export default {
    name: 'ListUnsubmittedSurveys',
    mixins: [GetLocalizedValue],
    props: {
        surveys: {
            type: Array
        }
    },
    data: () => ({
        currentTempId: null,
        showDeleteSurveyModal: false
    }),
    components: {
        ModalDeleteSurvey,
        Status
    },
    methods: {
        triggerDeleteModal(id) {
            this.currentTempId = id;
            this.showDeleteSurveyModal = true;
        },
        deleteSurvey() {
            console.log('delte', this.currentTempId);
            deletePendingSurveyByID(this.currentTempId);
            this.$emit('surveyDeleted');
        },
        gotoSurvey(survey) {
            this.$router.push({
                name: 'survey-detail',
                params: {
                    surveyId: survey.id,
                    surveyVersion: survey.version,
                    mode: 'resume-survey',
                    tempId: survey.tempIdFromDB
                }
            });
        }
    }
};
</script>
