<template>
    <div>
        <v-toolbar color="primary" dark flat>
            <v-btn icon @click="goBack">
                <v-icon large>arrow_back</v-icon>
            </v-btn>
            <v-toolbar-title>{{$t('survey.pending.title')}}</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <loading v-if="isLoading"/>
        <div v-if="!isLoading">
            <div class="mb-3">
                <div style="background-color:#f1f1f1; padding: 10px 15px;" v-if="pendingSurveys.length > 0">
                    <h2 class="title">{{$t('survey.pending.label')}}</h2>
                </div>
                <list-pending-surveys :pending-surveys="pendingSurveys" v-if="pendingSurveys.length > 0"/>
                <no-surveys-available :text="$t('survey.pending.empty')"
                                      v-if="pendingSurveys.length === 0" />
            </div>
        </div>
        <modal-survey-locked v-if="onShowLockedSurveyModal" />
    </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import ListPendingSurveys from '../../../../components/Surveys/Pending/ListPendingSurveys';
import NoSurveysAvailable from '../../../../components/Surveys/NoSurveysAvailable';
import ModalSurveyLocked from '../../../../components/Surveys/Modals/ModalSurveyLocked';

export default {
    name: 'SurveysPending',
    components: {ModalSurveyLocked, NoSurveysAvailable, ListPendingSurveys},
    data: () => ({
        unSubmittedSurveys: [],
        isLoading: false,
        isError: false
    }),
    computed: {
        ...mapState('surveys', ['pendingSurveys']),
        ...mapState('modals', ['onShowLockedSurveyModal'])
    },
    methods: {
        ...mapMutations('surveys', ['clearSurveyAnswers', 'clearSurveyFiles']),
        goBack() {
            this.$router.back();
        },
        fetchPendingSurveys() {
            this.isLoading = true;
            this.$store.dispatch('surveys/getSurveysByStatus', 'pending').then(() => {
                this.isLoading = false;
            });
        },
        fetchPendingSurveysFromStorage() {
            this.isLoading = true;
            this.$store.dispatch('surveys/getPendingSurveysFromIndexedDB').then(() => {
                this.isLoading = false;
            });
        }
    },
    created() {
        if (this.isOnline) {
            this.fetchPendingSurveys();
        }
        if (!this.isOnline) {
            this.fetchPendingSurveysFromStorage();
        }
        this.clearSurveyFiles();
        this.clearSurveyAnswers();
    }
};
</script>
