<template>
    <div>
        <v-toolbar color="primary" dark flat>
            <v-btn icon @click="goBack">
                <v-icon large>arrow_back</v-icon>
            </v-btn>
            <v-toolbar-title>{{$t('survey.drafts.title')}}</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <loading v-if="isLoading"/>
        <div v-if="!isLoading">
            <div class="mb-3 pb-10">
                <div style="background-color:#f1f1f1; padding: 10px 15px;">
                    <h2 class="title">{{$t('survey.drafts.label')}}</h2>
                </div>
                <list-unsubmitted-surveys @surveyDeleted="fetchPendingSurveysFromIndexedDB" :surveys="unSubmittedSurveys"/>
                <no-surveys-available :text="$t('survey.drafts.empty')"
                                      v-if="unSubmittedSurveys.length === 0" />
            </div>
        </div>
    </div>
</template>

<script>
import {mapMutations} from 'vuex';
import {getPendingSurveysFromDB} from '../../../../helpers/indexedDB';
import NoSurveysAvailable from '../../../../components/Surveys/NoSurveysAvailable';
import ListUnsubmittedSurveys from '../../../../components/Surveys/Pending/ListUnsubmittedSurveys';

export default {
    name: 'SurveysDrafts',
    components: {
        ListUnsubmittedSurveys,
        NoSurveysAvailable
    },
    data: () => ({
        unSubmittedSurveys: [],
        isLoading: false,
        isError: false
    }),
    methods: {
        ...mapMutations('surveys', ['clearSurveyAnswers', 'clearSurveyFiles']),
        goBack() {
            this.$router.back();
        },
        fetchPendingSurveysFromIndexedDB() {
            getPendingSurveysFromDB().then((response) => {
                this.unSubmittedSurveys = [...response];
            });
        }
    },
    created() {
        this.fetchPendingSurveysFromIndexedDB();
        this.clearSurveyFiles();
        this.clearSurveyAnswers();
    }
};
</script>
